import { Component, OnInit } from '@angular/core';
import { Vehicle, Oper, OperResponse } from 'src/app/models/data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { MarkerService} from 'src/app/services/marker.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

declare global {
  interface Window { L: any; }
}
window.L = window.L || {};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  m: any;
  osm: any;

  session: string;
  loading: boolean = false;

  vehicles: Vehicle[];

  constructor(private modalService: NgbModal, private dataService:DataService, private router: Router) { 
    this.session = sessionStorage.getItem("session");
  }

  ngOnInit() {
    this.m = window.L.map('mapid').setView([41.6154, 21.7514], 9);
    var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
  
    this.osm = new window.L.TileLayer(osmUrl, {minZoom: 6, maxZoom: 18, attribution: osmAttrib});		

	  this.m.setView(new window.L.LatLng(41.6154, 21.7514),9);
    this.m.addLayer(this.osm);

    this.m.attributionControl.setPrefix(false);
    console.log("done");
   // this.loadMaps();

    var t = this;
    this.m.on('click', function(e) {
      t.mapClick(e);
    });

    this.m.on('zoomend', function() {
      t.zoomChanged();
    });

    this.loadVehicles();
  }

  public loadVehicles() {
    this.loading = true;
    console.log('loadVehicles clicked');

    var sl = sessionStorage.getItem("vehicles");
    if (sl != null) {
      this.vehicles = JSON.parse(sl);
      this.loading = false;
      return;
    }

    // Ask server now

    var o = new Oper();
    o.oper = "vehicle_list";
    o.session = this.session;

    console.log('request : '+JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log(sdata);
      if (data.success) {
        console.log("success yes");
        this.vehicles = data.vehicleList;

        var svehicles = JSON.stringify(this.vehicles);
        sessionStorage.setItem("vehicles", svehicles);

        this.loading = false;
      } else {
        console.log("success no");
        this.loading = false;
      }
    });

  }

  mapClick(e) {
  }

  zoomChanged() {
  }

  loadRoutes(uid) {
    console.log("loadRoutes("+uid+")");
  }

}

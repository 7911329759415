import { Component, OnInit, ViewChild } from '@angular/core';
import { Vehicle, Oper, OperResponse } from 'src/app/models/data';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import {map} from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {

  loading: boolean = false;

  modalOption: NgbModalOptions = {}; // not null!
  
  session: string;

  makes: string[];
  models: string[];
  years: string[];
  branchs: string[];
  groups: string[];

  modalReference: any;

  closeResult: string;
  searchTerm: string;
  vehicles: Vehicle[];

  evehicleName: string;
  evehicleUid: string;
  evehicleVin: string;

  maxSpeed: string;

  public make: any;
  public model: any;
  public year: any;
  public branch: any;
  public group: any;

  selected_img: string = 'img01.png';
  selected_img_name: string = '01';

  constructor(private modalService: NgbModal, private dataService:DataService, private router: Router) { 
    this.session = sessionStorage.getItem("session");

  }

  ngOnInit() {
    this.loadVehicles();
  }

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  public loadVehicles() {
    this.loading = true;
    console.log('loadVehicles clicked');

    var sl = sessionStorage.getItem("vehicles");
    if (sl != null) {
      this.vehicles = JSON.parse(sl);
      this.loading = false;
      return;
    }

    // Ask server now

    var o = new Oper();
    o.oper = "vehicle_list";
    o.session = this.session;

    console.log('request : '+JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log(sdata);
      if (data.success) {
        console.log("success yes");
        this.vehicles = data.vehicleList;
        this.initArrays();

        var svehicles = JSON.stringify(this.vehicles);
        sessionStorage.setItem("vehicles", svehicles);

        this.loading = false;
      } else {
        console.log("success no");
        this.loading = false;
      }
    });

  }

  initArrays() {
    this.makes = [];
    this.models = [];
    this.years = [];
    this.branchs = [];
    this.groups = [];
  
    this.vehicles.forEach(element => {
      this.add(element.make, this.makes);
      this.add(element.model, this.models);
      this.add(element.year, this.years);
      this.add(element.branch, this.branchs);
      this.add(element.group, this.groups);
    });
  }

  add(a,b) {
    if (a==null || a==undefined) return;
    if (b.indexOf(a)<0)
      b.push(a);
  }

  clear() {
    this.evehicleName = "";
    this.evehicleUid = "";
    this.evehicleVin = "";
  
    this.make = "";
    this.model = "";
    this.year = "";
    this.branch = "";
    this.group = "";

    this.maxSpeed = "";
  
    this.selected_img = 'img01.png';
    this.selected_img_name = '01';
  }

  fill(v: Vehicle) {
    this.evehicleName = v.name;
    this.evehicleUid = v.id;
    this.evehicleVin = v.vin;
  
    this.make = v.make;
    this.model = v.model;
    this.year = v.year;
    this.branch = v.branch;
    this.group = v.group;
  
    this.maxSpeed = v.maxspeed;

    this.selected_img = v.image;
    this.selected_img_name = v.image.substr(3, 2);
  }

  open(content, isnew, v) {
    if (isnew) this.clear();
    if (!isnew && v!=null) this.fill(v);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;

    this.modalReference = this.modalService.open(content,this.modalOption);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  searchMake = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term.length < 0 ? []
      : this.makes.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  searchModel = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term.length < 0 ? []
      : this.models.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  searchYear = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term.length < 0 ? []
      : this.years.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
  
  searchBranch = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term.length < 0 ? []
      : this.branchs.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  searchGroup = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term.length < 0 ? []
      : this.groups.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  closeVehicle() {
    //alert('ha ha');
    console.log('closeVehicle()');
    this.modalReference.close();
  }

  saveVehicle() {
    /*console.log('saveVehicle()');
    console.log('Name : '+this.evehicleName);
    console.log('IMEI : '+this.evehicleUid);
    console.log('Vin : '+this.evehicleVin);
    console.log('Make : '+this.make);
    console.log('Model : '+this.model);
    console.log('Year : '+this.year);
    console.log('Branch : '+this.branch);
    console.log('Group : '+this.group);
    console.log('Image : '+this.selected_img);*/

    var v: Vehicle = new Vehicle();
    var o = new Oper();
    o.oper = "vehicle_save";
    o.session = this.session;
    o.vehicle = v;

    v.imei = this.evehicleUid;
    v.vin = this.evehicleVin;
    v.name = this.evehicleName;
    v.make = this.make;
    v.model = this.model;
    v.year = this.year;
    v.branch = this.branch;
    v.group = this.group;
    v.image = this.selected_img;

    v.maxspeed = this.maxSpeed;
    
    console.log('request : '+JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log(sdata);
      if (data.success) {
        console.log("success yes");
        this.modalReference.close();
        sessionStorage.removeItem("vehicles");
        this.loadVehicles(); // Reload vehicles
      } else {
        console.log("success no");
        // ???
        alert("Error "+data.errorMessage);
      }
    });


  }

  selectImg(img, img_name) {
    this.selected_img = img;
    this.selected_img_name = img_name;
  }
}

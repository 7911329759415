import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { Backend } from 'src/app/utils/backend';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  session: string;
  userType: string;

  constructor(private modalService: NgbModal, private dataService: DataService, private router: Router) {
    this.session = sessionStorage.getItem("session");
    this.userType = sessionStorage.getItem("usertype");
  }

  ngOnInit() {
  }

  logout() {
    Backend.logout(this.dataService, this.router, this.session);
  }

}

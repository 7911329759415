import { Component, OnInit } from '@angular/core';
import { TranslationEN, TranslationIL, TranslationRU, TranslationMK } from 'src/app/models/trans';
import { DataService } from 'src/app/services/data.service';
import { Oper, OperResponse } from 'src/app/models/data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  trans: any;
  lng: string;
  username: string;
  password: string;

  constructor(private dataService:DataService, private router: Router) { 
    this.lng = "en";
    sessionStorage.setItem("lng", this.lng);
    this.trans = TranslationEN;
  }

  ngOnInit() {
  }

  onLngChange(newValue) {
    sessionStorage.setItem("lng", newValue);
    this.trans = this.dataService.getTrans();
  }

  public loginClick() {
    console.log('loginClick clicked');
    // Ask server now

    var o = new Oper();
    o.oper = "login";
    o.username = this.username;
    o.password = this.password;

    console.log('request : '+JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log(sdata);
      if (data.success) {
        console.log("success yes");
        sessionStorage.setItem("session", data.session);
        sessionStorage.setItem("usertype", data.userType);
        this.router.navigateByUrl('/main');
      } else {
        console.log("success no");
        alert(data.errorMessage);
        sessionStorage.removeItem("session");
        sessionStorage.removeItem("usertype");
      }
    });

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { LoginComponent } from '../pages/login/login.component';
import { CustomerComponent } from '../pages/customer/customer.component';
import { VehiclesComponent } from '../pages/vehicles/vehicles.component';
import { VehicleComponent } from '../pages/vehicle/vehicle.component';
import { AuthGuardService as AG} from '../services/auth-guard.service';
import { MainComponent } from '../pages/main/main.component';
import { ClientComponent } from '../pages/client/client.component';
import { CustomersComponent } from '../pages/customers/customers.component';
import { NewcustomerComponent } from '../pages/newcustomer/newcustomer.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'main', component: MainComponent , canActivate: [AG] },
  { path: 'home', component: HomeComponent , canActivate: [AG] },
  { path: 'client', component: ClientComponent , canActivate: [AG] },
  { path: 'vehicles', component: VehiclesComponent , canActivate: [AG] },
  { path: 'vehicle', component: VehicleComponent , canActivate: [AG] },
  { path: 'customer', component: CustomerComponent , canActivate: [AG] },
  { path: 'customers', component: CustomersComponent , canActivate: [AG] },
  { path: 'newcustomer', component: NewcustomerComponent , canActivate: [AG] }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }


export const TranslationEN = {
    signIn: 'Sign in',
    signInWelcome: 'Welcome to VMS',
    usernamePlaceholder: 'Email or login',
    passwordPlaceholder: '******',
    forgotPassword: 'Forgot password?',
    login: 'Login',

    cellphone: 'Cell phone',
    contactnamen: 'Contact name',

    createNewCustomer: 'Create new customer'
}

export const TranslationIL = {
    cellphone: 'Cell phone',
    contactnamen: 'Contact name',
}

export const TranslationMK = {
    signIn: 'Најава',
    signInWelcome: 'Добродојдовте на VMS',
    usernamePlaceholder: 'Маил или корисничко име',
    passwordPlaceholder: '******',
    forgotPassword: 'Заборавивте лозинка?',
    login: 'Најава',

    cellphone: 'Cell phone',
    contactnamen: 'Contact name',

    createNewCustomer: 'Креирајте нов клиент'
}

export const TranslationRU = {
    cellphone: 'Cell phone',
    contactnamen: 'Contact name',
}

import * as L from '../../assets/leaflet.js';

export class Geo {

    public static distance(lat1, lon1, lat2, lon2) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
          return 0;
        }
        else {
          var radlat1 = Math.PI * lat1/180;
          var radlat2 = Math.PI * lat2/180;
          var theta = lon1-lon2;
          var radtheta = Math.PI * theta/180;
          var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          if (dist > 1) {
            dist = 1;
          }
          dist = Math.acos(dist);
          dist = dist * 180/Math.PI;
          dist = dist * 60 * 1.1515;
          dist = dist * 1.609344;
          return dist;
        }
      }
    
    public static genIcon(name) {
      var icon_name = 'assets/images/start-icon.png';
      var shadov_name = 'assets/images/start-icon.png';
      if (name=='start') {
        icon_name = 'assets/images/start-icon.png';
        shadov_name = 'assets/images/start-icon.png';
        }
      if (name=='stop') {
        icon_name = 'assets/images/finish-icon.png';
        shadov_name = 'assets/images/finish-icon.png';
      }
      var icon = L.icon({
        iconUrl: icon_name,
        shadowUrl: shadov_name,
    
        iconSize:     [16, 16], // size of the icon
        shadowSize:   [0, 0], // size of the shadow
        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
     });      
     return icon;
  }
}

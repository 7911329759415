export class Oper {
    oper?: string;
    session?: string;
    username?: string;
    password?: string;
    id?: string;
    vehicle?: Vehicle;
    poi?: POI;
	ncustomer?: NewCustomer;
	
	imei?: string;
	date?: string;
	hour?: string;
}

export class OperResponse {
    success?: boolean;
    errorMessage?: string;
	session?: string;
	userType?: string;

	vehicleList?: Vehicle[];
	poiList?: POI[];

	live?: LiveData[];

	data?: LP[];
	vdata?: VLP[];
	events?: VEvent[];
}

export class Vehicle {
	id?: string;
	name?: string;
	vin?: string;
	created?: string;
	image?: string;
	make?: string;
	model?: string;
	year?: string;
	branch?: string;
    group?: string;
	
	maxspeed?: string;
    imei?: string;
}

export class POI {
	id?: string;
	color?: string;
	name?: string;
	latitude?: number;
	longitude?: number;
	radius?: number;
	active?: boolean;
	type?: string;
	points?: any;
}

export class NewCustomer {
	name?: string;
	code?: string;
	email?: string;
	phone?: string;
	address?: string;
	username?: string;
	password?: string;
}

export class LiveData {
	imei?: string;
	created?: string;
	received?: string;
	event?: number;
	latitude?: number;
	longitude?: number;
	altitude?: number;
	satellites?: number;
	speed?: number;

	name?: string;
	vin?: string;

	vehicle?: Vehicle;
}

export class LP {
	r?: number; // received

	l?: number; // latitude
	o?: number; // longitude
	t?: number; // altitude
	
	a?: number; // angle
	s?: number; // speed

	n?: number; // Satellites

	e01?: string;
	e10?: string;
	e15?: string;
	e42?: string;
	eC7?: string;

	eEF?: string;
	eF0?: string;
	eFD?: string;
	eFE?: string;
}

export class VLP {
	l?: number[];

	eFD?: string;
	eFE?: string;
}

export class VRoute {
	list: LP[];
	startIndex: number;
	stopIndex: number;
	start: Date;
	end: Date;
	distance: number;
	distances: string;
	maxSpeed: number;
	avgSpeed: number;

	tripTime: number;
	tripTimes: string;

	starts:string;
	ends:string;
	color: string;

	poly: any;
}

export class VEvent {
	imei?: string;
	dt?: string;
	created?: number;
	createds?: string;
	latitude?: number;
	longitude?: number;
	poiid?: string;
	poiname?: string;
	poilatitude?: number;
	poilongitude?: number;
	poiradius?: number;
	direction?: string;
	directions?: string;
	waittime?: number;
	waittimes?: string;
}
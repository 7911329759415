import { formatDate } from "@angular/common";

export class Util {

    /**
     * Convert today date to 'yyyyMMdd'
     */
    public static todayAsString() {
        const format = 'yyyyMMdd';
        const myDate =  new Date();
        const locale = 'en-US';
        return formatDate(myDate, format, locale);
      }
    
    /**
       * Convert unix time stamp to 'dd/MM/yyyy HH:mm:ss'
       * @param dt 
       */
    public static unixToDateTime(dt) {
        var date = new Date(dt);
    
        const format = 'dd/MM/yyyy HH:mm:ss';
        const locale = 'en-US';
        return formatDate(date, format, locale);
    }

    /**
       * Convert unix time stamp to 'dd/MM/yyyy HH:mm:ss'
       * @param dt 
       */
      public static unixToTime(dt) {
        var date = new Date(dt);
    
        const format = 'HH:mm:ss';
        const locale = 'en-US';
        return formatDate(date, format, locale);
    }

    /**
       * Convert unix time stamp to 'dd/MM/yyyy HH:mm:ss'
       * @param dt 
       */
      public static unixToTimeUTC(dt) {
        var date = new Date(dt);
    
        const format = 'HH:mm:ss';
        const locale = 'en-US';
        return formatDate(date, format, locale, 'UTC');
    }
    
    /**
     * Convert date from date picker to 'yyyyMMdd'
     * 
     * @param selectedDate
     */
    public static convertDateToString(selectedDate:any) {
        //const format = 'yyyyMMdd';
        var sy = ""+selectedDate.year;
        var sm = ""+selectedDate.month;
        var sd = ""+selectedDate.day;
        if (sm.length==1) sm = "0" + sm;
        if (sd.length==1) sd = "0" + sd;
        return sy+sm+sd;
    }
    
    /**
     * Return today date for date picker
     */
    public static getTodayForDatePicker() {
        var date = new Date();
        var ngbDateStructTo = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() };
        return ngbDateStructTo;
    }

    public static distanceToString(n:number):string {
        var m = n / 1000;
        return  m.toFixed(1)+" km";
    }
}

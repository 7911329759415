import { AfterViewInit, Component, OnInit } from '@angular/core';
import { icon, Marker } from '../../../assets/leaflet.js';
import * as L from '../../../assets/leaflet.js';
import { MarkerService } from '../../services/marker.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service.js';
import { Oper, OperResponse, POI, Vehicle, LiveData, LP, VRoute, VEvent } from 'src/app/models/data.js';
import { formatDate } from '@angular/common';
import { Util } from 'src/app/utils/util.js';
import { Backend } from 'src/app/utils/backend.js';
import { PoiUtil } from 'src/app/utils/poi.js';
import { faTrashAlt,faEye,faEyeSlash, faSearchLocation, faMapMarkedAlt, faDrawPolygon} from '@fortawesome/free-solid-svg-icons';
import { RouteUtils } from 'src/app/utils/routeutils.js';

const iconRetinaUrl = 'assets/images/marker-icon-2x.png';
const iconUrl = 'assets/images/marker-icon.png';
const shadowUrl = 'assets/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  // faCoffee = faCoffee;
  faTrashAlt = faTrashAlt;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faSearchLocation = faSearchLocation;
  faMapMarkedAlt = faMapMarkedAlt;
  faDrawPolygon = faDrawPolygon;

  isNavbarCollapsed = true;
  s_mob_resolution:boolean = false;

  location: any;
  marker: any;
  cmarker: any;
  circle: any;
  radius: any = 10;
  all: any;
  circlePos: any;

  poi_name: any;
  poi_color: any;
  added_lat: any;
  added_lng: any;
  added_latlng: any;
  added_radius: any;
  id_cirk: any;
  s_custome_range:boolean = false;
  s_show_radius:boolean = true;
  selectedCustRad: number;

  name_validation:boolean = false;

  navOpened: boolean = false;

  session: string;
  userType: string;

  poilist: POI[];
  vehicles: Vehicle[];
  live: LiveData[] = null;

  markerList: any[] = [];
  polygonArray:any;
  polygon:any;

  m: any;
  osm: any;
  map;
  public check_addPOI = false;
  public check_addcustomPOI = false;

  editableLayers: any;

  selectedDate: any;
  selectedDateEvent: any;

  selectedImei: Vehicle;
  selectedImeiEvent: Vehicle;

  loading: boolean = false;

  vehicleData: LP[];
  vehicleRoutes: VRoute[];

  eventsList: VEvent[];

  poiLayer : any = null;
  routeLayer : any = null;

  constructor(public markerService: MarkerService, private modalService: NgbModal, private dataService: DataService, private router: Router) {
    this.session = sessionStorage.getItem("session");
    this.userType = sessionStorage.getItem("usertype");
    this.selectedDate = Util.getTodayForDatePicker();
    this.selectedDateEvent = Util.getTodayForDatePicker();
  }

  ngOnInit() {
    var innerWidth = window.innerWidth;
    if(innerWidth <= 600){
      this.s_mob_resolution = true;
    }
    console.log("Resolution: "+innerWidth);
    this.initMap();
   

    var t = this;
    t.map.on('click', function (e) {
      console.log(e.latlng);
      t.addmarker(e);
      t.addpolygon(e);

    });
    

    this.loadVehicles();
    this.loadPOIList();
  }

  

  locatePOI(lat,lng){
    if (this.location) {
      this.map.removeLayer(this.location);
    }
    var latlng1 = {"lat" : lat, "lng" :lng};
    this.location = L.marker([lat,lng]).addTo(this.map);
    this.map.setView(latlng1,13);
  }

  addmarker(e) {
    if (this.check_addPOI == true) {
      if (this.all) {
        this.map.removeLayer(this.all);
      }
      // document.getElementById("marker_options").style.width = "15%";
      // document.getElementById("marker_options").style.paddingLeft = "5px";
      // document.getElementById("marker_options").style.paddingRight = "10px";
      var marker_opt = document.getElementById("marker_options");
      marker_opt.classList.add("overlay-open-options");
      this.poi_color = '#3388FF';

      this.marker = new L.Marker(e.latlng, { draggable: true });
      this.circle = new L.circle(e.latlng, { radius: this.radius });
      var circlePos;
      this.circlePos = e.latlng;

      this.added_lat = e.latlng.lat;
      this.added_lng = e.latlng.lng;
      this.added_latlng = this.added_lat + ',' + this.added_lng;

      this.marker.on('dragend', function (e) {
        if (this.circle) {
          this.map.removeLayer(this.circle);
        }
        this.circle = new L.circle(e.target.getLatLng(), { radius: this.radius });
        this.all = L.layerGroup([this.marker, this.circle]);
        this.map.addLayer(this.all);
      });

      this.marker.bindPopup('<b>This is our selected POI !</b>').openPopup();
      this.all = L.layerGroup([this.marker, this.circle]);
      this.map.addLayer(this.all);
    }

  }
  addpolygon(e){
    if (this.check_addcustomPOI == true) {

      if(this.polygon){
        this.map.removeLayer(this.polygon);
      }

      if(this.polygonArray == undefined){
        this.polygonArray = [];
      }      

      // this.polygonArray =  [[41.992670579035966,21.354103102348745],[42.00313160367933,21.37264253105969]] ;   
      console.log(e.latlng);
      this.polygonArray.push(e.latlng);

      console.log("The first point of POligon: "+this.polygonArray[0]);

      var start_point = new L.Marker(this.polygonArray[0]);
      start_point.addTo(this.map).bindPopup("Starting Point");
      
      if (this.cmarker) {
        this.map.removeLayer(this.cmarker);
      }

      this.cmarker = new L.Marker(e.latlng);
      this.cmarker.addTo(this.map).bindPopup("Last point");
      console.log( this.polygonArray);

             
      // var coords = document.getElementById("coordPolygon").value;
      // var a = JSON.parse(this.coords);
      this.polygon = new L.polygon(this.polygonArray, {color: 'red'});
      this.polygon.addTo(this.map);
      // this.map.fitBounds(this.polygon.getBounds());
      console.log("We have  poligon !!!");

      // document.getElementById("marker_options").style.width = "15%";
      // document.getElementById("marker_options").style.paddingLeft = "5px";
      // document.getElementById("marker_options").style.paddingRight = "10px";
      var marker_opt = document.getElementById("marker_options");
      marker_opt.classList.add("overlay-open-options");

      this.poi_color = '#3388FF';

      

    }
  }

  initMap() {
    this.map = L.map('mapid', {
      center: [41.9974315, 21.423647],
      zoom: 13,
      zoomControl: true
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map)
    this.map.zoomControl.setPosition('bottomright');    
  }

  public addNewPoi(latlng) {
    L.marker([latlng.lat, latlng.lng], { draggable: true }).addTo(this.map);
  }

  changeRad(event: any) {
    var selectedRad = event.target.value;
    // alert("create new Circle !!!");
    console.log('range is Changed' + selectedRad);

    if (this.circle) {
      this.map.removeLayer(this.circle);
    }
    if (selectedRad == "") {
      this.circle = new L.circle(this.circlePos, { radius: 10 });
      this.s_custome_range = false;
    }
    if (selectedRad == "10") {
      this.circle = new L.circle(this.circlePos, { radius: 10 });
      this.s_custome_range = false;
    }
    if (selectedRad == "20") {
      this.circle = new L.circle(this.circlePos, { radius: 20 });
      this.radius = 20;
      this.s_custome_range = false;
    }
    if (selectedRad == "50") {
      this.circle = new L.circle(this.circlePos, { radius: 50 });
      this.radius = 50;
      this.s_custome_range = false;
    }
    if (selectedRad == "100") {
      this.circle = new L.circle(this.circlePos, { radius: 100 });
      this.radius = 100;
      this.s_custome_range = false;
    }
    if (selectedRad == "200") {
      this.circle = new L.circle(this.circlePos, { radius: 200 });
      this.radius = 200;
      this.s_custome_range = false;
    }
    if (selectedRad == "custome_radius") {
      // this.circle = new L.circle(this.circlePos, { radius: 200 });
      this.radius = 1;
      this.s_custome_range = true;
      this.selectedCustRad = 1;
    }

    this.all = L.layerGroup([this.marker, this.circle]);
    this.map.addLayer(this.all);
    console.log(this.all);
  }

  changeCustRad(event: any){

    var selCustRad = event.target.value;
    console.log('Custome range is Changed: ' + selCustRad);
    if (this.circle) {
      this.map.removeLayer(this.circle);
    }

    this.circle = new L.circle(this.circlePos, { radius: selCustRad });
    this.radius = selCustRad;

    this.all = L.layerGroup([this.marker, this.circle]);
    this.map.addLayer(this.all);
    console.log(this.all);
  }

  togleNav() {
    if (this.navOpened) {
      this.closeNav();
    } else {
      this.openNav();
    }
  }

  openNav() {
    this.navOpened = true;
    // document.getElementById("myNav").style.width = "25%";
    var open_oeverly = document.getElementById("myNav")
    open_oeverly.classList.add("overlay-open");
  }

  closeNav() {
    this.navOpened = false;
    // document.getElementById("myNav").style.width = "0%";
    var open_oeverly = document.getElementById("myNav")
    open_oeverly.classList.remove("overlay-open");
  }

  addPOI() {
    if (this.check_addPOI == false ) {
      var addPOI_text = document.getElementById("addPOI");
      addPOI_text.innerText = "Close POI";

      var x = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

      this.check_addPOI = true;

    } else {

      // document.getElementById("marker_options").style.width = "0%";
      // document.getElementById("marker_options").style.paddingLeft = "0px";
      // document.getElementById("marker_options").style.paddingRight = "0px";
      var marker_opt = document.getElementById("marker_options");
      marker_opt.classList.remove("overlay-open-options");

      if (this.all) {
        this.map.removeLayer(this.all);
      }

      var addPOI_text = document.getElementById("addPOI");
      addPOI_text.innerText = "Add New POI";
      this.check_addPOI = false;
    }
  }
  add_polygonPOI() {
    if (this.check_addcustomPOI == false) {
      var addPOI_text = document.getElementById("add_polygonPOI");
      addPOI_text.innerText = "Close Custom POI";

      var x = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000)

      this.check_addcustomPOI = true;
      this.s_show_radius = false;
    }else {

      // document.getElementById("marker_options").style.width = "0%";
      // document.getElementById("marker_options").style.paddingLeft = "0px";
      // document.getElementById("marker_options").style.paddingRight = "0px";
      var marker_opt = document.getElementById("marker_options");
      marker_opt.classList.remove("overlay-open-options");

      var addPOI_text = document.getElementById("add_polygonPOI");
      addPOI_text.innerText = "Add Custom POI";
      this.check_addcustomPOI = false;
      this.s_show_radius = true;
    }
  }

  clearAllPOI(ref){
    ref.map.removeLayer(ref.poiLayer);
    ref.poiLayer = null;
  }

  savePOI() {
    if(this.poi_color == undefined){
      this.poi_color = '#3388FF'
    }


    if( (this.poi_name == undefined) || (this.poi_name == "" )){
      this.name_validation =true;
    }else{
      this.name_validation =false;

      var poi = new POI();
      poi.name = this.poi_name;
      poi.active = true;
      poi.color = this.poi_color;
      poi.latitude = this.added_lat;
      poi.longitude = this.added_lng;
      if(this.check_addPOI){
        poi.type = 'circle';
        poi.radius = this.radius;
      }
      if( this.check_addcustomPOI){
        poi.type = 'custom';
        poi.points = this.polygonArray;
      }
  
      var o = new Oper();
      o.oper = "poi_save";
      o.session = this.session;
      o.poi = poi;
  
      console.log('request : ' + JSON.stringify(o));
      this.dataService.execOper(o).subscribe((data: OperResponse) => {
        var sdata = JSON.stringify(data);
        console.log(sdata);
        if (data.success) {
          console.log("success yes");
  
          var succesSave = document.getElementById("SaveMessage");
          succesSave.innerText = "You have successfully Save new POI !";
  
          var x = document.getElementById("SaveMessage");
          x.className = "show";
          setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
  
          // document.getElementById("marker_options").style.width = "0%";
          // document.getElementById("marker_options").style.paddingLeft = "0px";
          // document.getElementById("marker_options").style.paddingRight = "0px";
          var marker_opt = document.getElementById("marker_options");
          marker_opt.classList.remove("overlay-open-options");
          
          var addPOI_text = document.getElementById("addPOI");
          addPOI_text.innerText = "Add POI";
          this.check_addPOI = false;
          this.check_addcustomPOI = false;
  
          this.refreshPOIList();
        } else {
          console.log("success no");
          alert("Error " + data.errorMessage);
  
          var succesSave = document.getElementById("SaveMessage");
          succesSave.innerText = "Something wrong with Saving. Please try again !";
  
          var x = document.getElementById("SaveMessage");
          x.className = "show";
          setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
        }
      });
    }   
  }

  removePOI(id, name: string) {
    if(confirm("Are you sure to delete "+name)) {
      Backend.deletePOI(this.dataService, this.session, id, this, this.deletePOICallBack);
    }
  }

  deletePOICallBack(ref, success:boolean, msg:string) {
    if (success) {
      // Reload POI List now
      sessionStorage.removeItem("poilist");
      ref.clearAllPOI(ref);
      ref.loadPOIList();
    } else {
      alert(msg);
    }
  }

  logout() {
    Backend.logout(this.dataService, this.router, this.session);
  }

  public loadPOIList() {
    this.loading = true; 
    Backend.loadPOIList(this.dataService, this.session, this, this.loadPOIListCallBack);
  }

  public loadPOIListCallBack(ref, pl: POI[]) {
    ref.poilist = pl;
    PoiUtil.displayPOIList(pl, ref);
    ref.loading = false;
  }

  public loadVehicles() {
    this.loading = true;
    Backend.loadVehicles(this.dataService, this.session, this, this.loadVehiclesCallBack);
  }

  public loadVehiclesCallBack(ref, list:Vehicle[]) {
    ref.vehicles = list;
    ref.loading = false;
  }

  public loadLiveData() {
    console.log('loadLiveData()');

    this.loading = true;
    var o = new Oper();
    o.oper = "data_live";
    o.session = this.session;

    console.log('request : ' + JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log(sdata);
      if (data.success) {
        console.log("success yes");
        this.live = null;

        var tempLive: LiveData[] = data.live;


        tempLive.forEach(element => {
          if (element.vehicle == null) {
            element.vehicle = this.findByImei(element.imei);
            if (element.vehicle != null) {
              element.name = element.vehicle.name;
              element.vin = element.vehicle.vin;
            }
          }
        });

        this.live = tempLive;

        this.displayLive();
        this.loading = false;
      } else {
        console.log("success no");
        this.loading = false;
      }
    });

  }

  findByImei(imei) {
    var a = null;
    this.vehicles.forEach(element => {
      if (element.id == imei) {
        a = element;
      }
    });
    return a;
  }

  utod(dt) {
    var date = new Date(dt);
    return date;
  }

  displayLive() {
    // clear old first
    this.markerList.forEach(element => {
      this.map.removeLayer(element);
    });

    this.markerList = [];

    this.live.forEach(element => {
      var hint = "";
      if (element.vehicle != null) {
        hint = "Name : " + element.vehicle.name + "<br>VIN : " + element.vehicle.vin;
      }
      hint += "<br>IMEI " + element.imei;
      hint += "<br>Created " + Util.unixToDateTime(element.created);
      hint += "<br>Received " + Util.unixToDateTime(element.received);

      var lm = L.marker([element.latitude, element.longitude]);
      lm.addTo(this.map).bindPopup(hint);

      this.markerList.push(lm);

    });
  }

  today() {
    const format = 'yyyyMMdd';
    const myDate = new Date();
    const locale = 'en-US';
    return formatDate(myDate, format, locale);
  }

  getRoutes(l) {
    Backend.getDataDailyDelta(this.dataService, this.session, l.imei, this.today(), this, this.getRoutesCallback);
  }

  selectedRoute(r) {
    if (this.routeLayer!=null) {
      this.map.removeLayer(this.routeLayer);
      this.routeLayer = null;
    }

    var list = [];
    // Display all except selected
    for (let i =0; i<this.vehicleRoutes.length; i++) {
      if (!(r===this.vehicleRoutes[i]))
         RouteUtils.displayRouteNew(this.vehicleRoutes[i], list, false);
    }

    // Display selected only
    for (let i =0; i<this.vehicleRoutes.length; i++) {
      if (r===this.vehicleRoutes[i])
        RouteUtils.displayRouteNew(this.vehicleRoutes[i], list, true);
    }

    this.routeLayer = L.layerGroup(list);
    this.map.addLayer(this.routeLayer);
  }

  getRoutesCallback(ref, vroute: LP[]) {
    ref.data = vroute;
    ref.vehicleData = vroute;
    ref.vehicleRoutes = RouteUtils.splitToRoutes(vroute);

    if (ref.routeLayer!=null) {
      ref.map.removeLayer(ref.routeLayer);

      ref.routeLayer = null;
    }

    var list = [];

    for (let i =0; i<ref.vehicleRoutes.length; i++) {
      RouteUtils.displayRouteNew(ref.vehicleRoutes[i], list, false);
    }

    ref.routeLayer = L.layerGroup(list);
    ref.map.addLayer(ref.routeLayer);

    ref.loading = false;
  }

  refreshPOIList() {
    sessionStorage.removeItem("poilist");
    this.loadPOIList();
  }

  getRoutesHistory() {
    if (this.selectedImei==null || this.selectedImei==undefined) return;
    this.loading = true;
    Backend.getDataDailyDeltaExt(this.dataService, this.session, this.selectedImei.id, Util.convertDateToString(this.selectedDate) , this, this.getRoutesCallback);
  }

  getEventsHistory() {
    if (this.selectedImeiEvent==null || this.selectedImeiEvent==undefined) return;
    this.loading = true;
    Backend.getEvents(this.dataService, this.session, this.selectedImeiEvent.id, Util.convertDateToString(this.selectedDateEvent) , this, this.getEventsCallback);
  }

  getEventsCallback(ref, list:VEvent[]) {
    ref.eventsList = list;
    ref.loading = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { LP, Oper, OperResponse, VRoute } from 'src/app/models/data';
import { formatDate } from '@angular/common';
import { Geo } from 'src/app/utils/geo';
import { Util } from 'src/app/utils/util';
import { Backend } from 'src/app/utils/backend';
import { RouteUtils } from 'src/app/utils/routeutils';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

  session: string;
  userType: string;
  imei: string;
  hour: string;
  selectedDate: any;
  displayHeaders: boolean = false;

  constructor(private modalService: NgbModal, private dataService: DataService, private router: Router) {
    this.session = sessionStorage.getItem("session");
    this.userType = sessionStorage.getItem("usertype");

    this.selectedDate = Util.getTodayForDatePicker();
  }

  ngOnInit() {
    this.imei = "356307048741762";
  }

  formatDataElementItem(e) {
    var sc = "<tr>";
    sc += "<td>" + Util.unixToDateTime(e.received) + "</td>";
    sc += "<td>" + e.event + "</td>";
    sc += "<td>" + e.latitude + " " + e.longitude + "</td>";
    sc += "<td>" + e.speed + "</td>";
    sc += "<td>" + e.altitude + "</td>";
    sc += "<td>" + e.satellites + "</td>";
    sc += "<td>" + e.angle + "</td>";
    sc += "<td>" + e.e01 + "</td>";
    sc += "<td>" + e.e02 + "</td>";
    sc += "<td>" + e.e09 + "</td>";
    sc += "<td>" + e.e15 + "</td>";
    sc += "<td>" + e.e18 + "</td>";
    sc += "<td>" + e.e42 + "</td>";
    sc += "<td>" + e.e45 + "</td>";
    sc += "<td>" + e.e48 + "</td>";
    sc += "<td>" + e.e50 + "</td>";
    sc += "<td>" + e.EF0 + "</td>";
    sc += "<td>" + e.EC7 + "</td>";
    sc += "<td>" + e.ECD + "</td>";
    sc += "<td>Other</td>";
    sc += "</tr>";
    return sc;
  }

  formatDataElementHeader() {
    var sc = "<td>Received</td>";
    sc += "<td>Event</td>";
    sc += "<td>Position</td>";
    sc += "<td>Speed</td>";
    sc += "<td>Altitude</td>";
    sc += "<td>Satellites</td>";
    sc += "<td>Angle</td>";
    sc += "<td>E01</td>";
    sc += "<td>E02</td>";
    sc += "<td>E09</td>";
    sc += "<td>E15</td>";
    sc += "<td>E18</td>";
    sc += "<td>E42</td>";
    sc += "<td>E45</td>";
    sc += "<td>E48</td>";
    sc += "<td>E50</td>";
    sc += "<td>EF0</td>";
    sc += "<td>EC7</td>";
    sc += "<td>ECD</td>";
    sc += "<td>Other</td>";
    return "<table border=\"1\"><tr>" + sc + "</tr>";
  }

  formatDataElement(e) {
    var text = "";

    //var selement = JSON.stringify(e);
    //text += selement;
    if (this.displayHeaders) {
      text = "<hr>";
      text += "<table border=\"1\">";
      text += "<tr>";
      text += "<td>IMEI</td>";
      text += "<td>DTE</td>";
      text += "<td>Created</td>";
      text += "<td>Received</td>";
      text += "<td>Total odometer</td>";
      text += "</tr>";

      text += "<tr>";
      text += "<td>" + e.imei + "</td>";
      text += "<td>" + e.dte + "</td>";
      text += "<td>" + Util.unixToDateTime(e.created) + " (" + e.created + ")</td>";
      text += "<td>" + Util.unixToDateTime(e.freceived) + " (" + e.freceived + ")</td>";
      text += "<td align=\"right\" >" + e.totalOdometer + "</td>";
      text += "</tr>";
      text += "</table><br>Data :<br>";

    }

    text += this.formatDataElementHeader();
    e.items.forEach(element => {
      var a: any = element;
      text += this.formatDataElementItem(a);
    });

    text += "</table>";

    if (this.displayHeaders) {
      text += "<br>";
    }

    return text;
  }

  getAllData() {
    var o = new Oper();
    o.oper = "data_get_all";
    o.session = this.session;
    o.imei = this.imei;
    o.date = Util.convertDateToString(this.selectedDate);

    console.log('request : ' + JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      var mdata: any = data;
      if (data.success) {
        console.log("success yes");

        console.log(sdata);

        var text = "";
        mdata.all.forEach(element => {
          var a: any = element;

          text += this.formatDataElement(element);
          text += "<br>";
        });

        document.getElementById("data").innerHTML = text;
      } else {
        console.log("success no");
      }
    });
  }

  dailyHeader() {
    var s = "<table border=\"1\"><tr>";
    s += "<td>Received</td>";
    s += "<td>Position</td>";
    s += "<td>Speed</td>";
    s += "<td>Altitude</td>";
    s += "<td>Angle</td>";
    s += "<td>E01</td>";
    s += "<td>EC7</td>";
    s += "<td>E42</td>";
    s += "<td>EF0</td>";
    s += "<td>Prior distance</td>";
    s += "<td>Prior time</td>";
    s += "</tr>";
    return s;
  }

  dailyElement(e, ep) {
    var s = "<tr>";
    s += "<td>" + Util.unixToDateTime(e.r) + "</td>";
    s += "<td>" + e.l + " " + e.o + "</td>";
    s += "<td>" + e.s + "</td>";
    s += "<td>" + e.t + "</td>";
    s += "<td>" + e.a + "</td>";
    s += "<td>" + e.e01 + "</td>";
    s += "<td>" + e.eC7 + "</td>";
    s += "<td>" + e.e42 + "</td>";
    s += "<td>" + e.eF0 + "</td>";
    if (ep != null) {
      s += "<td align=\"right\" >" + (Geo.distance(e.l, e.o, ep.l, ep.o) * 1000).toFixed(1) + "</td>";
      s += "<td align=\"right\" >" + ((e.r - ep.r) / 1000) + "</td>";
    } else {
      s += "<td></td>";
      s += "<td></td>";
    }
    s += "</tr>";
    return s;
  }

  getDataDaily(delta:boolean) {
    if (delta)
      Backend.getDataDailyDelta(this.dataService, this.session, this.imei, Util.convertDateToString(this.selectedDate), this, this.getDataDailyResponse);
    else
      Backend.getDataDaily(this.dataService, this.session, this.imei, Util.convertDateToString(this.selectedDate), this, this.getDataDailyResponse);
  }

  getDataDailyExt() {
      Backend.getDataDailyDeltaExt(this.dataService, this.session, this.imei, Util.convertDateToString(this.selectedDate), this, this.getDataDailyResponse);
  }

  getDataDailyResponse(ref, vroute : LP[]) {
    var text = ref.dailyHeader();

    for (let i = 0; i < vroute.length; i++) {
      if (i > 0) {
        text += ref.dailyElement(vroute[i], vroute[i - 1]);
      } else {
        text += ref.dailyElement(vroute[i], vroute[i - 1]);
      }
    }

    text += "</table>";
    text += "<br><hr><br><br>";

    var routes : VRoute[] = RouteUtils.splitToRoutes(vroute);
    for (let i=0; i<routes.length;i++) {
      text += "Route "+i+" with start "+routes[i].startIndex+" and "+routes[i].stopIndex+"<br>";
    }

    document.getElementById("data").innerHTML = text;
  }

  generateHourly() {
    var o = new Oper();
    o.oper = "generate_hourly_data";
    o.session = this.session;
    o.imei = this.imei;
    o.hour = this.hour;
    o.date = Util.convertDateToString(this.selectedDate);

    console.log('request : ' + JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log('response : ' + sdata);
      if (data.success) {
        console.log("success yes");
      } else {
        console.log("success no");
      }
    });
  }

  getLive() {

  }
  
}

import { VRoute, LP } from "../models/data";
import * as L from '../../assets/leaflet.js';
import { Util } from "./util";
import { Geo } from "./geo";

export class RouteUtils {

    public static log(msg: string) {
        console.log(msg);
    }

    public static nextColor(i): string {
        var n = i % 12;
        if (n == 0) return 'DarkSlateBlue';
        if (n == 1) return 'Crimson';
        if (n == 2) return 'DarkGreen';
        if (n == 3) return 'Teal';
        if (n == 4) return 'MediumBlue';

        if (n == 5) return 'Maroon';
        if (n == 6) return 'DimGray';
        if (n == 7) return 'DarkSlateGray';
        if (n == 8) return 'Black';

        if (n == 9) return 'Red';
        if (n == 10) return 'Green';
        if (n == 11) return 'Chocolate';
        return 'blue';
    }

    public static splitToRoutes(list: LP[]): VRoute[] {

        var routes: VRoute[] = [];
        var r: VRoute = null;

        // Each trip start with engine on / off
        for (let i = 0; i < list.length; i++) {
            if (list[i].e01 == "1") {
                // Engine on
                if (r == null) {
                    r = new VRoute();
                    r.color = RouteUtils.nextColor(routes.length);
                    r.list = list;
                    r.startIndex = i;
                    r.start = new Date(list[i].r);
                    r.stopIndex = i;
                    r.end = new Date(list[i].r);
                    r.maxSpeed = list[i].s;
                    routes.push(r);
                } else {
                    r.stopIndex = i;
                    r.end = new Date(list[i].r);
                    if (list[i].s > r.maxSpeed)
                        r.maxSpeed = list[i].s;
                }
            } else {
                // Engine off
                r = null;
            }
        }

        var finalroutes: VRoute[] = [];

        routes.forEach(element => {
            element.starts = Util.unixToTime(element.start);
            element.ends = Util.unixToTime(element.end);
            element.distance = Number(element.list[element.stopIndex].eC7) - Number(element.list[element.startIndex].eC7);

            element.distances = Util.distanceToString(element.distance);

            element.tripTime = element.end.getTime() - element.start.getTime();
            element.tripTimes = Util.unixToTimeUTC(element.tripTime);

            if (element.tripTime > 0)
                element.avgSpeed = Number((element.distance / (element.tripTime * (1 / 3600.0))).toFixed(0));

            if (element.distance>30) {
                finalroutes.push(element);
            }
        });

        console.log("Total routes " + finalroutes.length);
        return finalroutes;
    }

    public static displayRoute(m, r: VRoute) {

        console.log('Display route with color ' + r.color);
        var points = [];
        for (let i = r.startIndex; i <= r.stopIndex; i++) {
            var l = [];
            l.push(r.list[i].l);
            l.push(r.list[i].o);
            points.push(l);
        }

        var p = L.polyline(points, { color: r.color }).addTo(m);
    }

    public static createHint(r: VRoute) : string {
        var s = "<table>";
        s += "<tr><td>Started</td><td>"+Util.unixToTime(r.start)+"</td></tr>";
        s += "<tr><td>Ended</td><td>"+Util.unixToTime(r.end)+"</td></tr>";
        s += "<tr><td>Distance</td><td>"+r.distances+"</td></tr>";
        s += "<tr><td>Trip time</td><td>"+r.tripTimes+"</td></tr>";
        s += "<tr><td>Max speed</td><td>"+r.maxSpeed+"</td></tr>";
        s += "<tr><td>Avg speed</td><td>"+r.avgSpeed+"</td></tr>";

        s += "</table>";
        return s;
    }

    public static displayRouteNew(r: VRoute, list, selected:boolean) {

        console.log('Display route with color ' + r.color);
        var points = [];
        for (let i = r.startIndex; i <= r.stopIndex; i++) {
            var l = [];
            l.push(r.list[i].l);
            l.push(r.list[i].o);
            points.push(l);
        }


        var w = 3.0;
        if (selected) w = 5.0;
        var p = L.polyline(points, { color: r.color, weight: w });

        p.bindPopup(this.createHint(r));

        r.poly = p;

        list.push(p);

        if (selected) {
            var start = Geo.genIcon('start');
            var stop = Geo.genIcon('stop');
            var mstart = L.marker([r.list[r.startIndex].l, r.list[r.startIndex].o], {icon: start});
            var mstop = L.marker([r.list[r.stopIndex].l, r.list[r.stopIndex].o], {icon: stop});

            list.push(mstart);
            list.push(mstop);
        }
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from '../../assets/leaflet.js';

// declare global {
//   interface Window { L: any; }
// }
// window.L = window.L || {};

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  // capitals: string = '/assets/data/usa-capitals.geojson';

  constructor(private http: HttpClient) {
  }
  //constructor() { }

  makeCapitalMarkers(map: L.map): void {
    // makeCapitalMarkers(map: Window): void {
    // this.http.get(this.capitals).subscribe((res: any) => {
    //   for (const c of res.features) {
    //     const lat = c.geometry.coordinates[0];
    //     const lon = c.geometry.coordinates[1];
    //     const marker = L.marker([lon, lat]).addTo(map);
    //   }
    // });
    const marker = L.marker([ 42.0009844,21.4035484 ]).addTo(map).bindPopup("Name: POI name <br> Radius: 500 ");
  }

}

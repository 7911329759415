import { Injectable } from '@angular/core';
import { TranslationEN, TranslationIL, TranslationMK, TranslationRU } from '../models/trans';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  url = 'https://184bydk4g2.execute-api.eu-west-1.amazonaws.com/dev/vms';

  constructor(private http: HttpClient) { }

  execOper(body:any) {
    return this
      .http
      .post(`${this.url}`, body);
  }

  getTrans() {
    let lng = sessionStorage.getItem("lng");
    if (lng=="en")
      return TranslationEN;
    if (lng=="mk")
      return TranslationMK;
    
    return TranslationEN;
  }

}

import { DataService } from "../services/data.service";
import { Router } from "@angular/router";
import { Oper, OperResponse, POI, Vehicle, LP, VRoute, VEvent } from "../models/data";
import { rootRenderNodes } from "@angular/core/src/view";
import { Util } from "./util";
import { element } from "@angular/core/src/render3/instructions";

export class Backend {

    /**
     * Logout and clear all session values
     * @param dataService 
     * @param router 
     * @param session 
     */
    public static logout(dataService: DataService, router: Router, session: string) {
        var o = new Oper();
        o.oper = "logout";
        o.session = session;

        console.log('request : ' + JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
            sessionStorage.removeItem("session");
            sessionStorage.removeItem("poilist");
            sessionStorage.removeItem("vehicles");
            router.navigateByUrl('/login');
        });
    }

    /**
     * Load all POIs - from storage session if found, it not found then load from server and save to session storage as well
     * @param dataService 
     * @param session 
     */
    public static loadPOIList(dataService: DataService, session: string, ref, loadPOIListCallBack) {
        var sl = sessionStorage.getItem("poilist");
        var poilist: POI[] = null;
        if (sl != null && sl != undefined) {
            console.log(sl);
            poilist = JSON.parse(sl);

            console.log('POIs found in session storage');
            console.log('list : ' + JSON.stringify(poilist));
            loadPOIListCallBack(ref, poilist);
            return;
        }

        var o = new Oper();
        o.oper = "poi_list";
        o.session = session;

        console.log('request : ' + JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
            var sdata = JSON.stringify(data);
            console.log(sdata);
            if (data.success) {
                console.log("success yes");
                poilist = data.poiList;

                var spoilist = JSON.stringify(poilist);
                sessionStorage.setItem("poilist", spoilist);
                loadPOIListCallBack(ref, poilist);
                return;
            } else {
                console.log("success no");
                loadPOIListCallBack(ref, null);
            }
        });

    }

    /**
     * Load all POIs - from storage session if found, it not found then load from server and save to session storage as well
     * @param dataService 
     * @param session 
     */
    public static deletePOI(dataService: DataService, session: string, poiID:string, ref, deletePOICallBack) {
        var o = new Oper();
        o.oper = "poi_delete";
        o.session = session;
        o.id = poiID;

        console.log('request : ' + JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
            var sdata = JSON.stringify(data);
            console.log(sdata);
            if (data.success) {
                console.log("success yes");
                deletePOICallBack(ref, true, null);
                return;
            } else {
                console.log("success no");
                deletePOICallBack(ref, false, data.errorMessage);
            }
        });

    }

    /**
     * Load all vehicles - from storage session if found, it not found then load from server and save to session storage as well
     * @param dataService 
     * @param session 
     */
    public static loadVehicles(dataService: DataService, session: string, ref, loadVehiclesCallBack) {
        console.log('loadVehicles()');

        var vehicles: Vehicle[] = null;
        var sl = sessionStorage.getItem("vehicles");
        if (sl != null && sl != undefined) {
            vehicles = JSON.parse(sl);
            console.log('Vehicles found in session storage');
            console.log('list : ' + JSON.stringify(vehicles));
            loadVehiclesCallBack(ref, vehicles);
            return;
        }

        var o = new Oper();
        o.oper = "vehicle_list";
        o.session = session;

        console.log('request : ' + JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
            var sdata = JSON.stringify(data);
            console.log(sdata);
            if (data.success) {
                console.log("success yes");
                vehicles = data.vehicleList;

                var svehicles = JSON.stringify(vehicles);
                sessionStorage.setItem("vehicles", svehicles);

                loadVehiclesCallBack(ref, vehicles);
            } else {
                console.log("success no");
                loadVehiclesCallBack(ref, null);
            }
        });

    }

    public static getDataDaily(dataService: DataService, session:string, imei:string, sdate:string, ref, getDataDailyResponse) {
        var list: LP[] = null;
    
        var o = new Oper();
        o.oper = "data_daily";
        o.session = session;
        o.imei = imei;
        o.date = sdate;
    
        console.log('request : '+JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
          var sdata = JSON.stringify(data);
          if (data.success) {
            console.log("success yes");
            console.log(sdata);
    
            list = data.data;

            for (let i = 0; i<list.length; i++) {
                list[i].l = list[i].l / 10000000.0;
                list[i].o = list[i].o / 10000000.0;
            }

            getDataDailyResponse(ref, list);
          } else {
            console.log("success no");
            getDataDailyResponse(ref, null);
          }
        });
    }
    
    public static getDataDailyDelta(dataService: DataService, session:string, imei:string, sdate:string, ref, getDataDailyResponse )  {
        var list: LP[] = null;
    
        var o = new Oper();
        o.oper = "data_daily_delta";
        o.session = session;
        o.imei = imei;
        o.date = sdate;
    
        console.log('request : '+JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
          var sdata = JSON.stringify(data);
          if (data.success) {
            console.log("success yes");
            console.log(sdata);
    
            var m = data.vdata;
            // Convert vdata to data first
            data.data = [];
            for (let i = 0; i < m.length; i++) {
                let e = m[i];
                let _lp : LP = new LP();
                _lp.r = e.l[0]; 
                _lp.l = e.l[1]; 
                _lp.o = e.l[2]; 
                _lp.s = e.l[3]; 
                _lp.n = e.l[4]; 

                _lp.e01 = ""+e.l[5]; 
                _lp.e42 = ""+e.l[6]; 
                _lp.eC7 = ""+e.l[7];

                _lp.eFD = e.eFD;
                _lp.eFE = e.eFE;
                data.data.push(_lp);
            };

            list = data.data;

            for (let i = 1; i<list.length; i++) {
                list[i].r = list[i].r + list[i-1].r;
                list[i].l = list[i].l + list[i-1].l;
                list[i].o = list[i].o + list[i-1].o;
                list[i].t = list[i].t + list[i-1].t;
                list[i].s = list[i].s + list[i-1].s;
                list[i].a = list[i].a + list[i-1].a;
            }

            for (let i = 0; i<list.length; i++) {
                list[i].l = list[i].l / 10000000.0;
                list[i].o = list[i].o / 10000000.0;
            }

            getDataDailyResponse(ref, list);
          } else {
            console.log("success no");
            getDataDailyResponse(ref, null);
          }
        });
    }

    public static getDataDailyDeltaExt(dataService: DataService, session:string, imei:string, sdate:string, ref, getDataDailyResponse )  {
        var list: LP[] = null;
    
        var o = new Oper();
        o.oper = "data_daily_delta_ext";
        o.session = session;
        o.imei = imei;
        o.date = sdate;
    
        console.log('request : '+JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
          var sdata = JSON.stringify(data);
          console.log(sdata);
          if (data.success) {
            console.log("success yes");
    
            var m = data.vdata;
            // Convert vdata to data first
            data.data = [];
            for (let i = 0; i < m.length; i++) {
                let e = m[i];
                let _lp : LP = new LP();
                _lp.r = e.l[0]; 
                _lp.l = e.l[1]; 
                _lp.o = e.l[2]; 
                _lp.s = e.l[3]; 
                _lp.n = e.l[4]; 

                _lp.e01 = ""+e.l[5]; 
                _lp.e42 = ""+e.l[6]; 
                _lp.eC7 = ""+e.l[7];

                _lp.eFD = e.eFD;
                _lp.eFE = e.eFE;
                data.data.push(_lp);
            };

            list = data.data;

            for (let i = 1; i<list.length; i++) {
                if (list[i].r < 100000000000) {
                    list[i].r = list[i].r + list[i-1].r;
                    list[i].l = list[i].l + list[i-1].l;
                    list[i].o = list[i].o + list[i-1].o;
                    list[i].s = list[i].s + list[i-1].s;
                    list[i].n = list[i].n + list[i-1].n;
                }
            }

            for (let i = 0; i<list.length; i++) {
                list[i].l = list[i].l / 10000000.0;
                list[i].o = list[i].o / 10000000.0;
            }

            getDataDailyResponse(ref, list);
          } else {
            console.log("success no");
            getDataDailyResponse(ref, null);
          }
        });
    }
    
    public static findPriorEvent(list: VEvent[], index) : VEvent {
        for (let i=index-1;i>=0;i--) {
            if (list[i].poiid==list[index].poiid && list[i].direction=='in') return list[i];
        }
        return null;
    }

    public static getEvents(dataService: DataService, session:string, imei:string, sdate:string, ref, getEventsResponse )  {
        var list: VEvent[] = null;
    
        var o = new Oper();
        o.oper = "event_vehicle_list";
        o.session = session;
        o.id = imei;
        o.date = sdate;
    
        console.log('request : '+JSON.stringify(o));
        dataService.execOper(o).subscribe((data: OperResponse) => {
          var sdata = JSON.stringify(data);
          if (data.success) {
            console.log("success yes");
            console.log(sdata);
    
            list = data.events;

            list.forEach(element => {
                element.createds = Util.unixToTime(element.created);
                if (element.direction=='in')
                    element.directions = 'In';
                else
                    element.directions = 'Out';
            });

            for (let i=0;i<list.length;i++) {
                if (list[i].direction=='out') {
                    // compare with when it was in, and calculate time difference
                    var pri = Backend.findPriorEvent(list, i);
                    if (pri!=null) {
                        list[i].waittime = list[i].created - pri.created;
                        list[i].waittimes = Util.unixToTimeUTC(list[i].waittime);
                    }

                }
            }

            getEventsResponse(ref, list);
          } else {
            console.log("success no");
            getEventsResponse(ref, null);
          }
        });
    }

}

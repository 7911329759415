import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public router: Router) { }
  canActivate(): boolean {
    // check session
    var session = sessionStorage.getItem("session");
    if (session == null || session == "") {
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }

}

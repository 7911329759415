import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { NewCustomer, Oper, OperResponse } from 'src/app/models/data';
import { Backend } from 'src/app/utils/backend';

@Component({
  selector: 'app-newcustomer',
  templateUrl: './newcustomer.component.html',
  styleUrls: ['./newcustomer.component.css']
})
export class NewcustomerComponent implements OnInit {

  trans: any;
  userType: string;
  session: string;

  customerName: string;
  customerCode: string;
  customerEmail: string;
  customerPhone: string;
  customerAddress: string;
  customerUsername: string;
  customerPassword: string;

  isNavbarCollapsed: boolean = true;

  constructor(private dataService:DataService, private router: Router) { 
    this.trans = this.dataService.getTrans();    
    this.session = sessionStorage.getItem("session");
    this.userType = sessionStorage.getItem("usertype");
  }

  ngOnInit() {
  }

  createCustomer() {
    var v: NewCustomer = new NewCustomer();
    var o = new Oper();
    o.oper = "create_customer";
    o.session = this.session;
    o.ncustomer = v;

    v.name = this.customerName;
    v.code = this.customerCode;
    v.email = this.customerEmail;
    v.phone = this.customerPhone;
    v.address = this.customerAddress;
    v.username = this.customerUsername;
    v.password = this.customerPassword;

    console.log('request : '+JSON.stringify(o));
    this.dataService.execOper(o).subscribe((data: OperResponse) => {
      var sdata = JSON.stringify(data);
      console.log(sdata);
      if (data.success) {
        console.log("success yes");
        alert("Customer was created");
      } else {
        console.log("success no");
        alert("Error "+data.errorMessage);
      }
    });

  }

  logout() {
    Backend.logout(this.dataService, this.router, this.session);
  }
}
